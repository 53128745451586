<script setup lang="ts">
import RoomieTag from '@lahaus-roomie/roomie/src/components/RoomieTag/index.vue'
import RoomieIcon from '@lahaus-roomie/roomie/src/components/RoomieIcon/index.vue'
import RoomieButton from '@lahaus-roomie/roomie/src/components/RoomieButton/index.vue'
import AIIcon from '@lahaus-roomie/roomie/src/assets/icons/v2/ai.svg'
import VerifiedIcon from '@lahaus-roomie/roomie/src/assets/icons/v2/verified.svg'
import NewWindowIcon from '@lahaus-roomie/roomie/src/assets/icons/v2/new-window.svg'

const emit = defineEmits(['register-action'])
const formInformation = ['Andrés Ramírez', 'and****@gmail.com', '+57320456****']
const { t } = useI18n()
</script>

<script lang="ts">
export default {
  name: 'ReferralProgramInformation'
}
</script>

<template>
  <div class="referral-program-information">
    <div class="mb-48 md:mb-40">
      <div class="referral-program-information__tag">
        <ClientOnly>
          <RoomieTag
            id="la-haus-verified-tag"
            variant="information"
            size="md">
            <template #content>
              <span class="display-7 mr-4">
                {{ t('title_tag.1') }}
              </span>

              <span class="display-regular-7">
                {{ t('title_tag.2') }}
              </span>
            </template>
          </RoomieTag>
        </ClientOnly>
      </div>

      <h1 class="flex flex-col justify-center">
        <span class="">
          <span class="display-5 md:display-3">{{ t('title.1') }}</span>

          <span class="ml-8 display-6 md:display-4">{{ t('title.2') }}</span>
        </span>

        <span class="display-5 md:display-3">
          {{ t('title.3') }}
        </span>
      </h1>
    </div>

    <div class="referral-program-information__grid">
      <div class="referral-program-information__card">
        <div class="referral-program-information__content--col1">
          <h2 class="referral-program-information__title">
            {{ t('slides.1.title') }}
          </h2>

          <p class="referral-program-information__description">
            {{ t('slides.1.description') }}
          </p>
        </div>

        <div class="referral-program-information__content--col2">
          <div class="flex flex-col gap-8">
            <div
              v-for="(item, index) in formInformation"
              :key="index +item"
              class="bg-gray2-400 rounded-md flex items-center justify-between py-8 px-16 min-h-[5rem]">
              <span class="body-2-m">{{ item }}</span>

              <RoomieIcon custom-styles="w-24 h-24 text-spring-600">
                <VerifiedIcon />
              </RoomieIcon>
            </div>
          </div>
        </div>
      </div>

      <div class="referral-program-information__card">
        <div class="referral-program-information__content--col1">
          <h2 class="referral-program-information__title">
            {{ t('slides.2.title') }}
          </h2>

          <p class="referral-program-information__description">
            {{ t('slides.2.description') }}
          </p>
        </div>

        <div class="referral-program-information__content--col2">
          <div class="relative">
            <img
              src="https://assests-lahaus.imgix.net/static/images/referral-program/share-form.png?auto=compress,enhance,format&w=289&h=165&fit=crop"
              alt="Comparte y gana"
              class="rounded-[1.6rem]">

            <div class="flex justify-center items-center absolute right-[1.2rem] md:right-0 top-[-1.4rem] bg-lime-600 p-lh-16 rounded-[1.4rem] w-[6.3rem] h-[4.9rem]">
              <div class="px-6">
                <RoomieIcon custom-styles="block text-pine-600 w-20 h-20">
                  <NewWindowIcon />
                </RoomieIcon>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="referral-program-information__card">
        <div class="referral-program-information__content--col1">
          <h2 class="referral-program-information__title">
            {{ t('slides.3.title') }}
          </h2>

          <p class="referral-program-information__description">
            {{ t('slides.3.description') }}
          </p>
        </div>

        <div class="referral-program-information__content--col2">
          <div class="flex justify-center items-center bg-gray2-400 w-full h-[16.4rem] rounded-[1.6rem]">
            <div class="relative w-full">
              <div class="referral-program-information__price-content -top-20 w-[12rem]  bg-pine-200" />

              <div class="-top-16 w-[15rem]  bg-pine-300 referral-program-information__price-content" />

              <div class="-top-12 w-[19rem]  bg-pine-500 referral-program-information__price-content" />

              <div class="-top-8 w-[22rem]  bg-pine-600 referral-program-information__price-content px-lh-8">
                <div class="w-full h-full flex justify-center items-center gap-4 text-white">
                  <span class="flex flex-row items-baseline gap-4">
                    <span class="display-7">
                      {{ t('slides.3.price') }}
                    </span>

                    <span class="body-1-sb">
                      {{ t('slides.3.currency') }}
                    </span>
                  </span>

                  <RoomieIcon custom-styles="text-lime-600 w-20 h-20">
                    <AIIcon />
                  </RoomieIcon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-center mt-48 md:mt-56">
      <RoomieButton
        id="referral-program-button-register-now"
        data-lh-id="referral-program-button-register-now"
        :aria-label="t('register_now')"
        variant="primary"
        @click="emit('register-action')">
        {{ t('register_now') }}
      </RoomieButton>
    </div>
  </div>
</template>

<style src="./_index.scss" lang="scss"></style>
<i18n src="./i18n.json" lang="json" />
